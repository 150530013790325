import Characteristices from "../../Components/Characteristics/Characteristics";
import MyAudioPlayer from "../../Components/MyAudioPlayer/MyAudioPlayer"
import Header from "../../Components/Header/Header";
import CallToAction from "../../Components/CallToAction/CallToAction";

function Home() {
  return (
    <>
      <Header />
      <Characteristices />
      <MyAudioPlayer />
      <CallToAction />
    </>
  );
}

export default Home;
