import useIntersectionObserver from "../../Functions/IntersectionObserver";

function CallToAction() {
  // Animate on Scroll Oberser
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [resumeButtom, isResukeButtonVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [contactButton, isContactButtonVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <section
        data-testid="contact"
        id="contact"
        className=" mt-10 mb-10 ml-4 sm:ml-2 mr-4 sm:mr-2 bg-primaryBackground rounded-lg"
      >
        <div className="p-8 font-[sans-serif]">
          <div className="max-w-xl mx-auto text-center">
            <h2
              ref={titleRef}
              className={`text-primaryFont text text-4xl font-extrabold relative after:absolute after:-bottom-5 after:h-1 after:w-3/4 after:bg-primaryFont after:left-0 after:right-0 after:mx-auto after:rounded-full ${
                isTitleVisible ? "animate-fade" : "opacity-0"
              }`}
            >
              Let’s Connect!
            </h2>
            <div ref={descRef} className="mt-12">
              <p
                className={`text-secondaryFont text-base ${
                  isDescVisible ? "animate-fade-up" : "opacity-0"
                }`}
              >
                Get a glimpse of my skills and achievements by downloading my
                resume. Let's build something great together!
              </p>
            </div>

            <div className="flex max-sm:flex-col justify-center gap-6 mt-6">
              <a href="https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/resume/Sheroz%20Akram.pdf">
                <button
                  ref={resumeButtom}
                  type="button"
                  className={`min-w-[140px] rounded px-4 py-2.5 text-sm tracking-wider font-semibold outline-none border text-white border-primaryFont animate-delay-200 bg-primaryFont hover:bg-transparent hover:text-primaryFont transition-all duration-300 ${
                    isResukeButtonVisible ? "animate-fade-right" : "opacity-0"
                  }`}
                >
                  Resume
                </button>
              </a>

              <a href="/Contact">
                <button
                  ref={contactButton}
                  type="button"
                  className={`text-secondaryFont min-w-[140px] rounded px-4 py-2.5 text-sm tracking-wider font-semibold outline-none border animate-delay-200 border-secondaryFont hover:bg-secondaryBackground transition-all duration-300 ${
                    isContactButtonVisible ? "animate-fade-left" : "opacity-0"
                  }`}
                >
                  Contact
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CallToAction;
