import profilePicture from "./Profile.jpeg";
import headerBackground from "./HeaderBackground.mp4";
import useIntersectionObserver from "../../Functions/IntersectionObserver";

function Header() {
  // Animate on Scroll Oberser
  const [profileRef, isProfileVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  const [description, isDescriptionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <>
      <div className="relative container mx-auto m-4 top-5 ring-1 ring-secondaryFont rounded-lg bg-primaryBackground overflow-hidden">
        {/* Video as background */}
        <video
          className="absolute w-full h-full object-cover z-0"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={headerBackground} type="video/mp4" />
        </video>
        {/* Content overlay */}
        <div className="relative z-10 flex flex-wrap bg-opacity-70 bg-primaryBackground p-10 rounded-lg">
          <div
            ref={profileRef}
            className={`w-full md:w-1/4 flex flex-col gap-2 items-center justify-center ${
              isProfileVisible ? "animate-fade-right" : "opacity-0"
            }`}
          >
            <img
              src={profilePicture}
              className="w-32 h-32 ring-2 ring-secondaryFont rounded-full"
              alt=""
            />
            <h1 className="text-secondaryFont font-bold text-4xl md:text-2xl">
              Sheroz Akram
            </h1>
          </div>
          <div
            ref={description}
            className={`w-full md:w-3/4 flex flex-col justify-center ${
              isDescriptionVisible ? "animate-fade-left" : "opacity-0"
            }`}
          >
            <span className="text-secondaryFont text-xl text-justify m-2">
              Hey there! 👋 I'm Sheroz Akram, a determined software engineer
              with a deep passion for creating innovative solutions.
            </span>
            <span className="text-secondaryFont text-xl text-justify m-2">
              Passionate Software Engineer with a strong interest in learning
              new technologies. Skilled in software development and
              problem-solving, focused on creating innovative solutions.
              Experienced in building and maintaining high-quality applications,
              with a proven ability to deliver successful projects.
            </span>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default Header;
