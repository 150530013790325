import { useEffect, useState } from "react";
import LoadingOverlay from "./Components/LoadingOverlay/LoadingOverlay";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import Home from "./Pages/Home/Home";
import Footer from "./Components/Footer/Footer";
import Certificates from "./Components/Certificates/Certificates";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Dummy Loading Screen
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });
  return (
    <>
      {isLoading === true ? (
        <LoadingOverlay />
      ) : (
        <div className="bg-secondaryBackground min-h-[100vh]">
          <div className="mx-auto max-w-screen-lg">
            <NavigationBar />
            <Home />
            <Certificates />
            <Footer />
          </div>
          <div className="h-[100vh]"></div>
        </div>
      )}
    </>
  );
}

export default App;
