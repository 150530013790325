import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import "./style.css";
import useIntersectionObserver from "../../Functions/IntersectionObserver";

const certificateList = [
  {
    title: "Usable Security",
    imageUrl: "CERTIFICATE_LANDING_PAGE~AEGFT3W392DT.jpeg",
    urlLink: "https://coursera.org/verify/AEGFT3W392DT",
  },
  {
    title: "Introduction to Web Development with HTML, CSS, JavaScript",
    imageUrl: "CERTIFICATE_LANDING_PAGE~MX9TM4CUC63S.jpeg",
    urlLink: "https://coursera.org/verify/MX9TM4CUC63S",
  },
  {
    title: "Introduction to Cloud Computing",
    imageUrl: "CERTIFICATE_LANDING_PAGE~E4R23HDK4CE3.jpeg",
    urlLink: "https://coursera.org/verify/E4R23HDK4CE3",
  },
  {
    title: "React Fundamentals",
    imageUrl: "CERTIFICATE_LANDING_PAGE~Y59PT4QES5JA.jpeg",
    urlLink: "https://coursera.org/verify/Y59PT4QES5JA",
  },
  {
    title: "Getting Started with Git and GitHub",
    imageUrl: "CERTIFICATE_LANDING_PAGE~US7WTELGHVSV.jpeg",
    urlLink: "https://coursera.org/verify/US7WTELGHVSV",
  },

  {
    title: "Developing Front-End Apps with React",
    imageUrl: "CERTIFICATE_LANDING_PAGE~53NE4FTVZP95.jpeg",
    urlLink: "https://coursera.org/verify/53NE4FTVZP95",
  },
  {
    title: "Developing Back-End Apps with Node.js and Express",
    imageUrl: "CERTIFICATE_LANDING_PAGE~LCZB8C6F5BTS.jpeg",
    urlLink: "https://coursera.org/verify/LCZB8C6F5BTS",
  },
];

function Certificates() {
  const [certificateRef, isCertificateVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <section
        ref={certificateRef}
        data-testid="certificates"
        id="certificates"
        className={`mt-10 mb-10 ml-4 sm:ml-2 mr-4 sm:mr-2 bg-primaryBackground animate-delay-200 rounded-lg ${
          isCertificateVisible ? "animate-fade" : "opacity-0"
        }`}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className={``}
        >
          {certificateList.map((value, index) => {
            return (
              <SwiperSlide>
                <CertificateSlide
                  imageURl={`https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/certificates/${value.imageUrl}`}
                  title={value.title}
                  urlLink={value.urlLink}
                />
                ;
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    </>
  );
}

const CertificateSlide = ({ imageURl, title, urlLink }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center py-10">
        <a href={urlLink} className="w-3/4 sm:w-1/2">
          <h1 className="font-bold text-secondaryFont text-xl justify-start mb-2">
            {title}
          </h1>
          <img src={imageURl} alt={title} srcset="" className="" />
        </a>
      </div>
    </>
  );
};

export default Certificates;
