function Footer() {
  return (
    <>
      <footer className="flex flex-col space-y-5 justify-center mt-10 border-t pt-10">
        <nav className="flex justify-center flex-wrap gap-6 text-gray-500 font-medium">
          <a className="text-secondaryFont hover:text-primaryFont" href="/">
            Home
          </a>
          <a className="text-secondaryFont hover:text-primaryFont" href="/Projects">
            Projects
          </a>
          <a className="text-secondaryFont hover:text-primaryFont" href="/Skills">
            Skills
          </a>
          <a className="text-secondaryFont hover:text-primaryFont" href="/Contact">
            Contact
          </a>
        </nav>

        <div className="flex justify-center space-x-5">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-10" alt="Facebook" src="https://img.icons8.com/?size=100&id=13912&format=png&color=000000" />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-10" alt="LinkedIn" src="https://img.icons8.com/?size=100&id=13930&format=png&color=000000" />
          </a>
          <a
            href="https://x.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-10" alt="X (Twitter)" src="https://img.icons8.com/?size=100&id=ClbD5JTFM7FA&format=png&color=000000" />
          </a>
          <a
            href="https://github.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="w-10" alt="GitHub" src="https://img.icons8.com/?size=100&id=12599&format=png&color=000000" />
          </a>

          
        </div>
        <p className="text-center text-secondaryFont font-medium">
          &copy; {new Date().getFullYear()} Sheroz Akram. All rights reservered.
        </p>
      </footer>
    </>
  );
}

export default Footer;
