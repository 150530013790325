import AudioPlayer from "react-modern-audio-player";
import useIntersectionObserver from "../../Functions/IntersectionObserver";

const playList = [
  {
    name: "Memory Reboot",
    writer: "VØJ, Narvent",
    img: "https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/audios/audio01/image.png",
    src: "https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/audios/audio01/audio.mp3",
    id: 1,
  },
  {
    name: "Little Dark Age",
    writer: "MGMT",
    img: "https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/audios/audio02/image.jpg",
    src: "https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/audios/audio02/audio.mp3",
    id: 2,
  },
  {
    name: "i like the way you kiss me",
    writer: "Artemas",
    img: "https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/audios/audio03/image.jpg",
    src: "https://pub-fee0be1e1b3446a9a79d4fa060669629.r2.dev/assets/audios/audio03/audio.mp3",
    id: 3,
  },
];

function MyAudioPlayer() {
  // Animate on Scroll Oberser
  const [playerRef, isPlayerVisible] = useIntersectionObserver({
    threshold: 0.1,
  })
  return (
    <>
      <div ref={playerRef} className={`container mx-auto ${isPlayerVisible ? "animate-fade" : "opacity-0"}`}>
      <AudioPlayer activeUI={{ all: true, }} playList={playList} />
      </div>
    </>
  );
}

export default MyAudioPlayer;
