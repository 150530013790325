import { FaLinkedin } from "react-icons/fa6";

function NavigationBar() {
  return (
    <>
      <nav className="bg-black/30 backdrop-blur-md backdrop-brightness-125 shadow-gray-300 ring-1 ring-primaryBackground w-100 px-8 md:px-auto sticky z-20 top-5 rounded-lg">
        <div className="md:h-16 h-28 mx-auto md:px-4 container flex items-center justify-between flex-wrap md:flex-nowrap">
          <div className="text-secondaryFont md:order-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
              />
            </svg>
          </div>
          <div className="text-secondaryFont order-3 w-full md:w-auto md:order-2">
            <ul className="flex font-semibold justify-between">
              <li className="md:px-4 md:py-2 text-primaryFont">
                <a href="/">Home</a>
              </li>
              <li className="md:px-4 md:py-2 hover:text-primaryFont">
                <a href="/Projects">Projects</a>
              </li>
              <li className="md:px-4 md:py-2 hover:text-primaryFont">
                <a href="/Skills">Skills</a>
              </li>
              <li className="md:px-4 md:py-2 hover:text-primaryFont">
                <a href="/Contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="order-2 md:order-3">
            <a href="https://github.com/Sheroz-Akram/">
              <button
                type="button"
                class="text-white bg-[#0077B5] hover:bg-[#76a9c5] ring-1 gap-1 ring-secondaryFont font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500"
              >
                <FaLinkedin />
                LinkedIn
              </button>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavigationBar;
