import {
  FcCalendar,
  FcCollaboration,
  FcElectronics,
  FcIdea,
  FcOk,
  FcPuzzle,
  FcSearch,
} from "react-icons/fc";
import useIntersectionObserver from "../../Functions/IntersectionObserver";

const characteristics = [
  {
    icon: <FcPuzzle className="w-10 h-10" />,
    title: "Problem Solving",
    desc: "Strong problem-solving skills are utilized to address complex technical issues and find efficient solutions.",
  },
  {
    icon: <FcSearch className="w-10 h-10" />,
    title: "Attention to Detail",
    desc: "A keen eye for detail ensures accuracy and helps in avoiding mistakes during software development.",
  },
  {
    icon: <FcCollaboration className="w-10 h-10" />,
    title: "Collaboration",
    desc: "Effective communication and collaboration are key when working in teams to achieve successful project outcomes.",
  },
  {
    icon: <FcElectronics className="w-10 h-10" />,
    title: "Adaptability",
    desc: "Being adaptable and open to learning new tools, languages, and technologies helps in keeping up with the evolving field of software engineering.",
  },
  {
    icon: <FcIdea className="w-10 h-10" />,
    title: "Analytical Thinking",
    desc: "Strong analytical thinking allows for breaking down complex systems and processes into manageable components.",
  },
  {
    icon: <FcCalendar className="w-10 h-10" />,
    title: "Time Management",
    desc: "Good time management skills ensure deadlines are met and projects progress smoothly.",
  },
];

function Characteristices() {
  // Animate on Scroll Oberser
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <section id="chactertistics" className="py-10 flex justify-center">
        <div className="container">
          <div className="w-full mx-auto text-center">
            <h2
              ref={titleRef}
              className={`mb-4 text-3xl font-bold !leading-tight text-primaryFont sm:text-4xl md:text-[45px] ${
                isTitleVisible ? "animate-fade-right" : "opacity-0"
              }`}
            >
              Top Characteristics
            </h2>
            <p
              ref={descRef}
              className={`text-base !leading-relaxed text-body-color md:text-lg text-secondaryFont ${
                isDescVisible ? "animate-fade-up" : "opacity-0"
              }`}
            >
              I combine strong development skills with practical experience to
              build high-quality, user-centered software solutions.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-x-2 sm:gap-x-8 gap-y-2 md:grid-cols-2 lg:grid-cols-3 mb-5 ml-4 sm:ml-2 mr-4 sm:mr-2">
            {characteristics.map((value, index) => {
              return (
                <CharacteristicsItem
                  icon={value.icon || <FcOk className="w-10 h-10" />}
                  title={value.title}
                  description={value.desc}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

const CharacteristicsItem = ({ icon, title, description }) => {
  // Animate on Scroll Oberser
  const [charRef, isVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <div
      ref={charRef}
      className={`w-full mt-10 ${isVisible ? "animate-fade" : "opacity-0"}`}
    >
      <div className="wow fadeInUp">
        <div className="mb-2 w-full sm:w-1/4 p-5 flex items-center justify-center rounded-md bg-primaryBackground text-secondaryFont">
          {icon}
        </div>
        <h3 className="mb-1 text-xl font-bold text-primaryFont sm:text-2xl lg:text-xl xl:text-2xl">
          {title}
        </h3>
        <p className="pr-[10px] text-sm sm:text-base font-medium leading-relaxed text-secondaryFont text-justify">
          {description}
        </p>
      </div>
    </div>
  );
};

export default Characteristices;
